<template>
  <div>
    <wrapper-with-back :link="backLink" class="show-home-service">
      <ShowJob
        :loader="showBuyoutAmountLoader"
        :job-info="jobInfo"
        :status-heading="statusHeading"
        :show-cancel="hasStatus('requestAccepted', 'NEW', 'New', 'INITIATED', 'PAYMENT SUCCESSFUL')"
        :cancellation-popup-data="cancellationPopupData.job"
        @rerequest="$emit('rerequest')"
      >
        <div v-if="hasStatus('requestAccepted')">
          <div v-html="statusHtml"></div>
          <HomeServiceDateAndTimeBlock
            v-if="formattedCallInfo"
            class="show-home-service__date-and-time"
            :formatted="formattedCallInfo"
            :title="status.dateAndTimeTitle"
          />
        </div>
        <div v-else-if="hasStatus('visitScheduled')">
          <div v-html="statusHtml"></div>
          <HomeServiceTechnicianInformation :job-info="jobInfo" />
          <HomeServiceDateAndTimeBlock
            v-if="formattedCallInfo"
            class="show-home-service__date-and-time"
            :formatted="formattedCallInfo"
            :title="status.dateAndTimeTitle"
          />
        </div>
        <div v-else-if="hasStatus('customerNotReachable', 'customerNotAvailable')">
          <div v-html="statusHtml"></div>
          <HomeServiceTechnicianInformation :job-info="jobInfo" />
          <div v-html="getHtmlForProp('text2')"></div>
        </div>

        <BuyoutStatus
          v-else-if="hasStatus('deviceNotRepairable', 'amountAvailable', 'oopDeclined')"
          :cancellation-popup-data="cancellationPopupData.buyout"
          @confirm="cancelServifyClaimForBuyout"
          @decline="cancelServifyClaimForBuyout"
          @declined="$emit('rerequest')"
          @confirmed="$emit('rerequest')"
        >
          <span v-html="statusHtml"></span>
        </BuyoutStatus>

        <div v-else v-html="statusHtml" class="show-home-service__text"></div>
      </ShowJob>
    </wrapper-with-back>
  </div>
</template>
<script>
import ShowJob from './ShowJob';
import jobStatusMappingMixin from '@/mixins/jobStatusMappingMixin';
import BuyoutStatus from '@/components/job/BuyoutStatus';
import HomeServiceDateAndTimeBlock from '@/components/claim/components/home-service/HomeServiceDateAndTimeBlock';
import HomeServiceTechnicianInformation from '@/components/claim/components/home-service/HomeServiceTechnicianInformation';
import {statuses, statusCodesResolver} from '@/constants/home-service/statuses';
import cancellationPopupData from '@/constants/home-service/cancellation_popup_data';
import {formattedDateAndTime, dateToTimeRange} from '@/utils/dateUtils';

// TODO: resolve inconsistencies or confirm workarounds
//
// List of inconsistencies between the data and designs
//
// Absence of time of failure when technician wasn't able to reach the customer
//   uses same workaround as for absence of scheduled date time range
//   and displays the time range rather than an exact time
// Absence of technician's service centre
//   currently not being displayed

export default {
  components: {
    ShowJob,
    BuyoutStatus,
    HomeServiceDateAndTimeBlock,
    HomeServiceTechnicianInformation,
  },

  mixins: [jobStatusMappingMixin],

  computed: {
    cancellationPopupData: () => cancellationPopupData,
    statuses: () => statuses,
    statusCodesResolver: () => statusCodesResolver,
    backLink() {
      const {customerRef, contractRef} = this.$route.query;
      const query = {customerRef, contractRef};
      return {
        name: 'MyDevices',
        query,
      };
    },

    formattedCallInfo() {
      let formatted = null;
      const {jobInfo} = this;
      const {scheduledServiceDate, claimDetails} = jobInfo;
      const {scheduledFromTime, scheduledToTime} = claimDetails;

      if (scheduledServiceDate && scheduledFromTime && scheduledToTime) {
        formatted = formattedDateAndTime(scheduledServiceDate.split('T')[0], {
          from: scheduledFromTime.split(':'),
          to: scheduledToTime.split(':'),
        });
      }
      return formatted;
    },

    buyoutAmount() {
      return parseFloat(this.jobInfo?.claimDetails?.buyoutAmount);
    },
    showBuyoutAmountLoader() {
      if (!this.jobInfo?.serviceJobID?.toLowerCase().startsWith('hscm')) {
        return false;
      }
      return !this.buyoutAmount;
    },
  },
};
</script>
<style lang="scss" scoped>
.show-home-service {
  padding: 20px 0;
  color: $darkGray;
  font-size: 18px;

  @include mf($gridMDBreakpoint) {
    padding: 60px 0;
  }

  &__date-and-time {
    margin: 10px 0;
  }
}
</style>

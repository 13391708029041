<template>
  <div class="job-status-buttons" :class="{'job-status-buttons--no-margin': noMargin}">
    <hs-button
      class="job-status-buttons__action-button"
      size="md"
      variant="secondary"
      @click="$emit('decline')"
      :disabled="declineDisabled"
      >{{ declineButtonText }}</hs-button
    >
    <hs-button
      class="job-status-buttons__action-button"
      size="md"
      @click="$emit('confirm')"
      :disabled="disabled"
      >{{ confirmButtonText }}</hs-button
    >
  </div>
</template>
<script>
export default {
  props: {
    confirmButtonText: {
      type: String,
      required: true,
    },
    declineButtonText: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    declineDisabled: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.job-status-buttons {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: $gridLGGutter;
  padding: 0;
  &--no-margin {
    margin-top: 0;
  }

  @include mf($gridXSBreakpoint) {
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0 10px 0;
  }

  &__action-button {
    width: 209px;

    &:is(:last-child) {
      margin-bottom: 16px;
    }

    @include mf($gridMDBreakpoint) {
      width: unset;
    }

    @include mf($gridXSBreakpoint) {
      &:is(:last-child) {
        margin-bottom: 0;
      }
    }
  }
}
</style>

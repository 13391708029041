<template>
  <InfoBlock
    v-if="locationName"
    class="carry-in-repair-operating-hours"
    :title="`${locationName} hours:`"
  >
    <div class="carry-in-repair-operating-hours__text-wrapper">
      <p class="carry-in-repair-operating-hours__text" v-for="hours in locationHours" :key="hours">
        {{ hours }}
      </p>
    </div>
  </InfoBlock>
</template>
<script>
import InfoBlock from '@/components/job/InfoBlock';
// TODO: format and output job's location
// operating hours when integrating
export default {
  props: {
    jobInfo: {
      type: Object,
      required: true,
    },
  },
  components: {
    InfoBlock,
  },
  computed: {
    locationHours() {
      return this.jobInfo?.location?.hours;
    },
    locationName() {
      return this.jobInfo?.location?.name;
    },
  },
};
</script>
<style lang="scss" scoped>
.carry-in-repair-operating-hours {
  max-width: 346px;
  margin: 0;
  margin-top: 20px;
  padding: 16px;

  &__text-wrapper {
    margin-top: 5px;
  }
  &__text {
    font-weight: 700;
  }
}
</style>

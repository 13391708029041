<template>
  <div v-if="technician" class="home-service-technician-information">
    <div class="home-service-technician-information__icon"></div>
    <div class="home-service-technician-information__text-container">
      <p>Technician contact information is:</p>
      <p>
        <b
          ><a :href="phoneUri">{{ technician.phone }}</a
          >, {{ technician.name }}
        </b>
        <span v-if="technician.serviceCenterName">| {{ technician.serviceCenterName }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import {parsePhoneNumber} from 'libphonenumber-js';
export default {
  props: {
    jobInfo: {
      required: true,
    },
  },
  computed: {
    technician() {
      const {technicianName, technicianPhoneNumber} = this.jobInfo.claimDetails;
      return {
        name: technicianName,
        phone: technicianPhoneNumber,
      };
    },
    phoneUri() {
      const {phone} = this.technician;

      let uri = null;
      if (phone) {
        uri = parsePhoneNumber(phone, 'US').getURI();
      }

      return uri;
    },
  },
};
</script>
<style lang="scss" scoped>
.home-service-technician-information {
  display: flex;
  align-items: center;
  padding: 7px 0;
  &__icon {
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    margin-right: 15px;
    background-image: url('~@/assets/images/claim/phone-call.svg');
  }
}
</style>

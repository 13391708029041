<template>
  <div class="upload-receipt">
    <div class="upload-receipt__content">
      <div class="upload-receipt__section">
        <!--  <div>
          <p class="upload-receipt__text">Please upload your receipt here</p>
          <p class="upload-receipt__text">(*png, *jpg or *pdf format are aceptable)</p>
        </div>
        <DnDUploader @filechanged="handleFileChange" />
        <hs-button
          :disabled="!chosenFile"
          variant="wide"
          size="md"
          class="upload-receipt__upload"
          @click="proceedWithFile"
          >Upload</hs-button
        >
      </div>
      <div class="upload-receipt__section upload-receipt__section--last"> -->
        <p class="upload-receipt__text upload-receipt__text--with-mb">
          You can email us your receipt to:
          <a href="mailto:service@homeserve.com"><b>service@homeserve.com</b></a>
        </p>
        <p class="upload-receipt__text">
          Please write “Reimbursement” in the subject line of your email. You will receive an
          auto-reply email to indicate that your receipt has been received by the HomeServe team.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import DnDUploader from '@/components/claim/components/reimbursement/DnDFileUploader';

export default {
  components: {
    // DnDUploader,
  },
  data() {
    return {
      chosenFile: false,
    };
  },
  methods: {
    handleFileChange(val) {
      this.chosenFile = val;
    },
    proceedWithFile() {
      this.$store.commit('jobInfo/SET_CLAIM_STATUS', 'receipt at review');
    },
  },
};
</script>

<style scoped lang="scss">
.upload-receipt {
  width: 100%;
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 18px;
    font-size: 16px;

    @include mf($gridSMBreakpoint) {
      flex-direction: row;
    }
  }
  &__section {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mf($gridSMBreakpoint) {
      width: 50%;
    }

    &--last {
      margin-top: 40px;
      padding-left: 0;

      @include mf($gridSMBreakpoint) {
        margin-top: 0;
        padding-left: 45px;
      }

      @include mf($gridMDBreakpoint) {
        padding-left: 35px;
      }
    }
  }
  &__text {
    color: $darkGray;
    &--with-mb {
      margin-bottom: 18px;
    }
    &--smaller {
      font-size: 16px;
    }
  }
  &__email-text {
    &--no-wrap {
      white-space: nowrap;
    }
  }
  &__upload {
    align-self: center;
    margin-top: 20px;
  }
}
</style>

<template>
  <div class="info-block">
    <div v-if="title" class="info-block__title">{{ title }}</div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.info-block {
  max-width: 310px;
  padding: 10px;
  color: $darkGray;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  background-color: $lightestGray;
  border: 1px dashed $lightGray;
  border-radius: 4px;

  &__title {
    line-height: 170%;
  }
}
</style>

<template>
  <InfoBlock :title="title">
    <div>
      <b>{{ formatted.day }} {{ formatted.date }}</b>
    </div>
    <div>
      <b>from {{ formatted.from }} to {{ formatted.to }}</b>
    </div>
  </InfoBlock>
</template>
<script>
import InfoBlock from '@/components/job/InfoBlock';
export default {
  props: {
    title: {
      type: String,
    },
    formatted: {
      required: true,
      validator: (formatted) => ['day', 'date', 'from', 'to'].every((prop) => formatted[prop]),
    },
  },
  components: {
    InfoBlock,
  },
};
</script>

<template>
  <div class="buyout">
    <div>
      <div class="buyout__text">
        <slot></slot>
      </div>

      <StatusButtons
        v-bind="$props"
        @confirm="confirmBuyout"
        @decline="declineOrShowCancellationPopup"
        :disabled="disabled"
        :decline-disabled="disabled"
      />
    </div>

    <CancellationPopup
      v-if="cancellationPopupData && isBuyoutCancellationConfirmationVisible"
      v-bind="cancellationPopupData"
      @hide="hideBuyoutCancellationConfirmation"
      @decline="confirmBuyout"
      @confirm="declineBuyout"
      :disabled="disabled"
      :decline-disabled="disabled"
    />
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import CancellationPopup from '@/components/shared/CancellationPopup';
import StatusButtons from '@/components/job/StatusButtons';

const DEFAULT_DECLINE_BUTTON_TEXT = 'Decline Buyout';
const DEFAULT_CONFIRM_BUTTON_TEXT = 'Proceed with Buyout';

export default {
  // emits: ['disable', 'disabled', 'confirm', 'confirmed']
  props: {
    declineButtonText: {
      type: String,
      default: DEFAULT_DECLINE_BUTTON_TEXT,
    },
    confirmButtonText: {
      type: String,
      default: DEFAULT_CONFIRM_BUTTON_TEXT,
    },
    cancellationPopupData: {
      type: Object,
    },
    manualConfirmation: {
      type: Boolean,
      default: false,
    },
    manualDecline: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CancellationPopup,
    StatusButtons,
  },
  data() {
    return {
      disabled: false,
      isBuyoutCancellationConfirmationVisible: false,
    };
  },
  computed: {
    ...mapGetters('jobInfo', ['jobInfo']),
  },
  methods: {
    ...mapActions('requestService', ['ACCEPT_BUYOUT', 'REJECT_BUYOUT']),
    declineOrShowCancellationPopup() {
      if (this.cancellationPopupData) {
        this.showBuyoutCancellationConfirmation();
      } else {
        this.declineBuyout();
      }
    },
    showBuyoutCancellationConfirmation() {
      this.isBuyoutCancellationConfirmationVisible = true;
    },
    hideBuyoutCancellationConfirmation() {
      if (!this.disabled) {
        this.isBuyoutCancellationConfirmationVisible = false;
      }
    },
    async declineBuyout() {
      this.disabled = true;

      await this.$listeners.decline?.();

      if (this.manualDecline) {
        this.$emit('declined');
      } else {
        const {claimsMasterId} = this.jobInfo;
        await this.REJECT_BUYOUT(claimsMasterId);

        this.$emit('declined');
      }

      this.isBuyoutCancellationConfirmationVisible = false;
      this.disabled = false;
    },
    async confirmBuyout() {
      this.disabled = true;

      await this.$listeners.confirm?.();

      if (this.manualConfirmation) {
        this.$emit('confirmed');
      } else {
        const {claimsMasterId} = this.jobInfo;
        await this.ACCEPT_BUYOUT(claimsMasterId);

        this.$emit('confirmed');
      }

      this.isBuyoutCancellationConfirmationVisible = false;
      this.disabled = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.buyout {
  &__text {
    color: $darkGray;
    line-height: 160%;
  }
}
</style>

<template>
  <div>
    <wrapper-with-back :link="backLink" class="show-depot">
      <ShowJob
        :loader="showBuyoutAmountLoader"
        :job-info="jobInfo"
        :status-heading="statusHeading"
        :show-cancel="
          hasStatus('requestAccepted', 'NEW', 'New', 'INITIATED', 'PAYMENT SUCCESSFUL') &&
          !isCancelEligible
        "
        :cancellation-popup-data="cancellationPopupData.job"
        @rerequest="$emit('rerequest')"
      >
        <template v-if="hasStatus('requestAccepted') || isReAttend">
          <div>
            <span class="show-depot__text" v-html="statusHtml"></span>
            &nbsp;
            <GetDirections
              v-if="jobInfo.address.fullAddress && jobInfo.dropOffLocation.fullAddress"
              class="show-depot__get-directions"
              :from="jobInfo.address.fullAddress"
              :to="jobInfo.dropOffLocation.fullAddress"
            />
          </div>

          <div class="show-depot__shipping-label-container">
            <div class="show-depot__shipping-label-description">
              <div class="show-depot__text" v-html="getHtmlForProp('text2')"></div>
              <hs-button
                class="show-depot__shipping-label-download"
                @click="downloadShippingLabel"
                size="md"
                :disabled="!shippingLabelData"
                >Download</hs-button
              >
            </div>

            <div v-if="shippingLabelData">
              <object
                class="show-depot__shipping-label"
                aria-label="pdf"
                :data="shippingLabelData"
              ></object>
            </div>
          </div>
          <div class="show-depot__text" v-html="getHtmlForProp('text3')"></div>
        </template>

        <BuyoutStatus
          v-else-if="hasStatus('deviceNotRepairable', 'amountAvailable', 'oopDeclined')"
          :cancellation-popup-data="cancellationPopupData.buyout"
          @confirm="cancelServifyClaimForBuyout"
          @decline="cancelServifyClaimForBuyout"
          @declined="$emit('rerequest')"
          @confirmed="$emit('rerequest')"
        >
          <span v-html="statusHtml"></span>
        </BuyoutStatus>

        <div v-else v-html="statusHtml" class="show-depot__text"></div>
      </ShowJob>
    </wrapper-with-back>
  </div>
</template>
<script>
import ShowJob from './ShowJob';
import jobStatusMappingMixin from '@/mixins/jobStatusMappingMixin';
import {statuses, statusCodesResolver} from '@/constants/depot/statuses';
import download from '@/utils/download';
import BuyoutStatus from '@/components/job/BuyoutStatus';
import cancellationPopupData from '@/constants/depot/cancellation_popup_data';
import {compareStates, getStateCode} from '@/constants/us_states';
import GetDirections from '@/components/shared/GetDirections';

export default {
  components: {
    ShowJob,
    BuyoutStatus,
    GetDirections,
  },

  mixins: [jobStatusMappingMixin],

  methods: {
    downloadShippingLabel() {
      download({
        src: this.shippingLabelData,
        fileName: 'shipping-label',
      });
    },
  },

  computed: {
    statuses: () => statuses,
    statusCodesResolver: () => statusCodesResolver,

    cancellationPopupData: () => cancellationPopupData,
    backLink() {
      const {customerRef, contractRef} = this.$route.query;
      const query = {customerRef, contractRef};
      return {
        name: 'MyDevices',
        query,
      };
    },

    shippingLabelData() {
      let result = null;

      const shippingLabelFromCustomer = this.jobInfo.claimDetails?.shippingLabelFromCustomer;

      if (shippingLabelFromCustomer) {
        result = `data:application/pdf;base64,${shippingLabelFromCustomer}`;
      }

      return result;
    },
    isCancelEligible() {
      return this.jobInfo?.serviceJobStatus === 'CAN';
    },
    isReAttend() {
      return (
        this.jobInfo?.serviceType.toUpperCase() === 'RE-ATTEND' &&
        !this.jobInfo?.serviceJobSubStatusName
      );
    },
    buyoutAmount() {
      return parseFloat(this.jobInfo?.claimDetails?.buyoutAmount);
    },
    showBuyoutAmountLoader() {
      if (!this.jobInfo?.serviceJobID?.toLowerCase().startsWith('hscm')) {
        return false;
      }
      return !this.buyoutAmount;
    },
  },
};
</script>
<style scoped lang="scss">
.show-depot {
  padding: 20px 0;
  color: $darkGray;
  font-size: 18px;

  @include mf($gridMDBreakpoint) {
    padding: 60px 0;
  }

  &__text {
    line-height: 160%;
  }
  &__shipping-label-container {
    display: flex;
    padding: 20px 0 15px 0;
  }
  &__shipping-label-description {
    width: 340px;
  }
  &__shipping-label-download {
    margin-top: 15px;
  }
  &__shipping-label {
    align-self: baseline;
    width: 50%;
    height: 72px;
    transform: rotate(90deg) translateX(20px);
  }
}
</style>
<style lang="scss">
.show-depot {
  &__get-directions button {
    font-size: 18px !important;
  }
}
</style>

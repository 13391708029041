<template>
  <div class="job-claim">
    <div class="job-claim__date">{{ serviceDate }}</div>
    <div class="job-claim__description">{{ serviceStatus }}</div>
  </div>
</template>

<script>
import jobStatusTextMixin from '@/mixins/jobStatusTextMixin';

export default {
  props: {
    job: Object,
    serviceDate: String,
  },
  mixins: [jobStatusTextMixin],
  computed: {
    date() {
      return this.claim?.date;
    },
    completedSBJob() {
      return this.job?.serviceJobStatus === 'CMP' && this.job?.sourceSystem === 'SERVICEBENCH';
    },
    serviceStatus() {
      return this.completedSBJob ? 'Completed' : this.getJobStatusText(this.job);
    },
  },
};
</script>

<style scoped lang="scss">
.job-claim {
  padding: 25px 0;
  font-size: 16px;
  line-height: 19px;
  border-top: 1px solid $gray;

  @include mf($gridXSBreakpoint) {
    display: flex;
    align-items: center;
    padding: 25px 0;
    &__date {
      flex: 1 0 40%;
      max-width: 40%;
      padding-right: 20px;
    }
  }

  &__date {
    color: $black;
  }
  &__description {
    color: $darkGray;
    font-weight: bold;
  }
}
</style>

<template>
  <wrapper-with-back :link="backLink" class="show-buyout">
    <ShowJob
      :loader="showBuyoutAmountLoader"
      :job-info="jobInfo"
      :status-heading="completedSBJob ? 'Completed' : statusHeading"
    >
      <div v-if="hasStatus('amountAvailable')">
        <BuyoutStatus @confirmed="$emit('rerequest')" @declined="$emit('rerequest')">
          <span v-html="statusHtml"></span>
        </BuyoutStatus>
      </div>
      <div v-else-if="completedSBJob" class="show-buyout__text">
        Your Buyout has been successfully completed.
      </div>
      <div v-else v-html="statusHtml" class="show-buyout__text"></div>
    </ShowJob>
  </wrapper-with-back>
</template>
<script>
import jobStatusMappingMixin from '@/mixins/jobStatusMappingMixin';
import {statuses, statusCodesResolver} from '@/constants/buyout/statuses';
import ShowJob from './ShowJob';
import BuyoutStatus from '@/components/job/BuyoutStatus';

export default {
  mixins: [jobStatusMappingMixin],

  components: {
    ShowJob,
    BuyoutStatus,
  },

  data() {
    return {
      isCancellationConfirmationVisible: false,
    };
  },

  computed: {
    completedSBJob() {
      return (
        this.jobInfo?.serviceJobStatus === 'CMP' && this.jobInfo?.sourceSystem === 'SERVICEBENCH'
      );
    },
    statuses: () => statuses,
    statusCodesResolver: () => statusCodesResolver,
    backLink() {
      const {customerRef, contractRef} = this.$route.query;
      const query = {customerRef, contractRef};
      return {
        name: 'MyDevices',
        query,
      };
    },
    buyoutAmount() {
      return parseFloat(this.jobInfo?.claimDetails?.buyoutAmount);
    },
    showBuyoutAmountLoader() {
      if (!this.jobInfo?.serviceJobID?.toLowerCase().startsWith('hscm')) {
        return false;
      }
      return !this.buyoutAmount;
    },
  },
};
</script>
<style lang="scss" scoped>
.show-buyout {
  padding: 20px 0;
  color: $darkGray;
  font-size: 18px;

  @include mf($gridMDBreakpoint) {
    padding: 60px 0;
  }

  &__text {
    line-height: 160%;
  }
}
</style>

<template>
  <div class="job-claims">
    <job-claim :job="job" :serviceDate="serviceDate" />
  </div>
</template>

<script>
import JobClaim from '@/components/job/Claim';

export default {
  props: {
    job: Object,
    serviceDate: String,
  },
  components: {
    JobClaim,
  },
  data() {
    return {
      claims: [],
    };
  },
};
</script>

<style scoped lang="scss">
.job-claims {
  margin-top: 10px;
  border-bottom: 1px solid $gray;

  @include mf($gridMDBreakpoint) {
    margin-top: 25px;
  }
}
</style>

import {mapGetters, mapActions} from 'vuex';

export default {
  methods: {
    ...mapActions('requestService', ['CANCEL_SERVIFY_CLAIM']),
    async cancelServifyClaimForBuyout() {
      // const {claim, customerRef, contractRef, reason} = payload;
      const {jobInfo} = this;
      const {customerRef, contractRef} = this.$route.query;

      return this.CANCEL_SERVIFY_CLAIM({
        customerRef,
        contractRef,

        claim: jobInfo,

        reason: 'BUYOUT_CANCEL',
        skipUpdateMasterClaim: true,
      });
    },

    hasStatus(...statusesNames) {
      const statusCodes = statusesNames.map((statusName) => {
        if (this.statuses[statusName]?.virtualStatus === this.statusCode) {
          return this.statuses[statusName]?.virtualStatus;
        } else {
          return (
            this.statuses[statusName]?.virtualStatus ||
            this.statuses[statusName]?.code ||
            statusName
          );
        }
      });
      return statusCodes.some((statusCode) => {
        let hasStatus;
        if (Array.isArray(statusCode)) {
          hasStatus = statusCode.some((code) => this.statusCode === code);
        } else {
          hasStatus = this.statusCode === statusCode;
        }

        return hasStatus;
      });
    },

    getHtmlForProp(prop) {
      const value = this.status[prop];
      return value ? value(this.jobInfo) : '';
    },
  },
  computed: {
    ...mapGetters('jobInfo', ['jobInfo']),

    statusApiText() {
      const {serviceJobSubStatusName, serviceJobStatusName} = this.jobInfo;
      return serviceJobSubStatusName || serviceJobStatusName;
    },

    statusCode() {
      const {serviceJobSubstatus, serviceJobStatus} = this.jobInfo;
      if (
        this.jobInfo?.serviceJobSubStatusName === 'Buyout Rejected' &&
        this.jobInfo?.claimDetails?.scheduledFromTime // HACK to recognize carry in
      ) {
        return 'Carry In Rejected';
      } else if (
        this.jobInfo?.serviceJobSubStatusName === 'Buyout Rejected' &&
        this.jobInfo?.claimDetails?.dropOffLocationAddress // HACK to recognize depot
      ) {
        return 'Depot Rejected';
      } else if (
        // HACK to recognize manually cancelled replacement on the Options provided stage
        this.jobInfo?.serviceJobStatus === 'CAN' &&
        this.jobInfo?.serviceJobStatusName === 'Cancelled' &&
        this.jobInfo?.serviceJobType === 'CLAIM_REPLACEMENT_WITHOUT_DEFECTIVE'
      ) {
        return 'Replacement claim cancelled by customer';
      } else if (
        this.jobInfo?.serviceJobStatus === 'CAN' &&
        this.jobInfo?.serviceJobStatusName === 'Cancelled' &&
        this.jobInfo?.serviceJobType === 'CLAIM_CARRYIN'
      ) {
        return 'Repair cancelled';
      }
      if (serviceJobSubstatus == 'DropOff request accepted') {
        return 'Drop off request accepted';
      }
      return serviceJobSubstatus || serviceJobStatus;
    },

    status() {
      const {statusCode, statusApiText} = this;
      const unhandledStatus = {statusApiText};

      return this.statusCodesResolver(this.jobInfo, statusCode) ?? unhandledStatus;
    },

    statusHeading() {
      const {status, statusApiText, jobInfo} = this;
      const {serviceJobStatus} = jobInfo;
      const {heading, virtualStatus} = status;
      return heading || virtualStatus || statusApiText || serviceJobStatus;
    },

    statusHtml() {
      return this.getHtmlForProp('text');
    },
  },
};

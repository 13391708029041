<template>
  <div>
    <wrapper-with-back :link="backLink" class="show-carry-in">
      <ShowJob
        :loader="showBuyoutAmountLoader"
        :job-info="jobInfo"
        :status-heading="statusHeading"
        :show-cancel="
          hasStatus('serviceAccepted', 'NEW', 'New', 'INITIATED', 'PAYMENT SUCCESSFUL') &&
          !isCancelEligible
        "
        :cancellation-popup-data="cancellationPopupData.job"
        @rerequest="$emit('rerequest')"
      >
        <div v-if="hasStatus('serviceAccepted')">
          <div v-html="statusHtml"></div>

          <CarryInRepairOperatingHours :job-info="jobInfo" />

          <template>
            <div class="show-carry-in__schedule">Has your schedule changed?</div>
            <nav class="show-carry-in__tools">
              <router-link :to="editDateRoute" class="show-carry-in__action-link hs-link"
                >Change date</router-link
              >
              |
              <router-link :to="editLocationRoute" class="show-carry-in__action-link hs-link"
                >Change location</router-link
              >
            </nav>
          </template>
          <p class="show-carry-in__note">
            Note: Before dropping off your device, please back it up and unlock it (or provide us
            with your access code/password).
          </p>
        </div>

        <div v-else-if="hasStatus('deviceNotEligibleForRepair', 'repairCompleted')">
          <div v-html="statusHtml"></div>
          <CarryInRepairOperatingHours :job-info="jobInfo" />
        </div>

        <BuyoutStatus
          v-else-if="hasStatus('deviceNotRepairable', 'amountAvailable', 'oopDeclined')"
          :cancellation-popup-data="cancellationPopupData.buyout"
          @confirm="cancelServifyClaimForBuyout"
          @decline="cancelServifyClaimForBuyout"
          @declined="$emit('rerequest')"
          @confirmed="$emit('rerequest')"
        >
          <span v-html="statusHtml"></span>
        </BuyoutStatus>

        <div v-else v-html="statusHtml"></div>
      </ShowJob>
    </wrapper-with-back>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';
import ShowJob from './ShowJob';
import BuyoutStatus from '@/components/job/BuyoutStatus';
import cancellationPopupData from '@/constants/depot/cancellation_popup_data';
import CarryInRepairOperatingHours from '@/components/claim/components/carry-in-repair/CarryInRepairOperatingHours';
import {statuses, statusCodesResolver} from '@/constants/carry-in/statuses';
import jobStatusMappingMixin from '@/mixins/jobStatusMappingMixin';

export default {
  components: {
    ShowJob,
    BuyoutStatus,
    CarryInRepairOperatingHours,
  },

  mixins: [jobStatusMappingMixin],

  computed: {
    cancellationPopupData: () => cancellationPopupData,
    statuses: () => statuses,
    statusCodesResolver: () => statusCodesResolver,
    backLink() {
      const {customerRef, contractRef} = this.$route.query;
      const query = {customerRef, contractRef};
      return {
        name: 'MyDevices',
        query,
      };
    },

    jobId() {
      return this.$route.params.id;
    },

    editLocationRoute() {
      const {query} = this.$route;
      return {
        name: 'EditJob',
        params: {id: this.jobId},
        query: {...query, step: 'location'},
      };
    },
    editDateRoute() {
      const {query} = this.$route;
      return {
        name: 'EditJob',
        params: {id: this.jobId},
        query: {...query, step: 'date'},
      };
    },
    isCancelEligible() {
      return this.jobInfo?.serviceJobStatus === 'CAN';
    },
    buyoutAmount() {
      return parseFloat(this.jobInfo?.claimDetails?.buyoutAmount);
    },
    showBuyoutAmountLoader() {
      if (!this.jobInfo?.serviceJobID?.toLowerCase().startsWith('hscm')) {
        return false;
      }
      return !this.buyoutAmount;
    },
  },
  methods: {
    ...mapMutations('requestService/carryInRepair', ['SET_CHOSEN_LOCATION']),
  },
  watch: {
    ['jobInfo.location']: {
      handler(location = {}) {
        this.SET_CHOSEN_LOCATION(location.id);
      },
      immediate: true,
    },
  },
};
</script>


<style scoped lang="scss">
.show-carry-in {
  padding: 20px 0;

  @include mf($gridMDBreakpoint) {
    padding: 60px 0;
  }
  &__action-link {
    font-size: 18px;
  }
  &__schedule {
    margin-top: 23px;
    font-size: 18px;
    line-height: 160%;
  }
  &__note {
    margin-top: 12px;
    font-weight: 700;
    font-size: 18px;
  }
  &__tools {
    margin-top: 4px;
    font-size: 18px;
    line-height: 130%;
  }
}
</style>

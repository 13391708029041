<template>
  <div class="show-claim">
    <template v-if="loadingInProgress">
      <div class="show-claim__loader-cont">
        <i class="show-claim__loader"></i>
      </div>
    </template>
    <component v-else :is="currentPage" @rerequest="reRequestJob" />
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import Reimbursement from '@/components/job/show/ShowReimbursement.vue';
import CarryInRepair from '@/components/job/show/ShowCarryIn';
import ShowDepot from '@/components/job/show/ShowDepot';
import ShowReplacement from '@/components/job/show/ShowReplacement';
import ShowHomeService from '@/components/job/show/ShowHomeService';
import ShowBuyout from '@/components/job/show/ShowBuyout';

export default {
  components: {
    Reimbursement,
    CarryInRepair,
    ShowDepot,
    ShowReplacement,
    ShowHomeService,
    ShowBuyout,
  },
  data: () => ({
    reRequestJobInProgress: false,
  }),
  created() {
    this.CLEAR_STORE();
  },
  computed: {
    ...mapState('jobInfo', ['claimsList', 'jobInfo']),
    ...mapState('deviceInformation', ['modelName']),
    loadingInProgress() {
      const {reRequestJobInProgress} = this;
      const hasJobInfoAndModelName = this.jobInfo !== null && this.modelName !== null;
      return !hasJobInfoAndModelName || reRequestJobInProgress;
    },
    jobType() {
      return this.jobInfo?.serviceJobType?.toLowerCase();
    },
    jobId() {
      return this.$route.params.id;
    },
    seekingJob() {
      return this.claimsList ? this.claimsList.find((i) => i.serviceJobID === this.jobId) : null;
    },
    currentPage() {
      switch (this.jobType) {
        case 'claim_self_repair':
          return 'Reimbursement';
        case 'claim_carryin':
          return 'CarryInRepair';
        case 'claim_dropoff':
          return 'ShowDepot';
        case 'replacement': // workaround for eaerlier Replacement claims
        case 'claim_replacement_without_defective':
          return 'ShowReplacement';
        case 'claim_onsite':
          return 'ShowHomeService';
        case 'claim_buyout_without_defective':
        case 'buyout - reject':
        case 'manual buyout':
        case 'buyout - accept':
          return 'ShowBuyout';
      }
      return '';
    },
    isBuyoutClaim() {
      // Based on HS- status name we assume that a claim is buyout.
      // Make sure that statement is consistent.
      return this.seekingJob?.serviceJobID?.toLowerCase().startsWith('hscm');
    },
  },
  watch: {
    jobId: {
      async handler(id) {
        const {customerRef} = this.$route.query;
        this.$store
          .dispatch('user/DECRYPT_PARAMS', {
            customerRef,
          })
          .then(async (resp) => {
            await this.$store
              .dispatch('jobInfo/GET_CLAIMS_LIST', {
                customerRef: resp?.customerRef,
                serviceJobID: this.jobId,
              })
              .then(() => {
                this.SET_JOB_INFO(this.seekingJob);
              });

            // If claim type is Buyout, call get amount API.
            if (this.isBuyoutClaim) {
              await this.$store.dispatch('jobInfo/GET_BUYOUT_AMOUNT', {
                serviceJobId: this.jobId,
              });
            }
          });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('jobInfo', ['SET_JOB_INFO', 'CLEAR_STORE']),
    async reRequestJob() {
      if (this?.seekingJob?.serviceJobSubstatus !== 'SC0106') {
        return location.reload();
      }

      this.reRequestJobInProgress = true;

      const {customerRef} = this.$route.query;
      const resp = await this.$store.dispatch('user/DECRYPT_PARAMS', {
        customerRef,
      });
      await this.$store.dispatch('jobInfo/GET_CLAIMS_LIST', {
        customerRef: resp?.customerRef,
        serviceJobID: this.jobId,
      });
      this.SET_JOB_INFO(this.seekingJob);

      this.reRequestJobInProgress = false;
    },
  },
  beforeDestroy() {
    this.CLEAR_STORE();
  },
};
</script>

<style scoped lang="scss">
.show-claim {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &__loader-cont {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }
  &__loader {
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    background-image: url('~@/assets/images/hs-loader.gif');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% auto;
  }
}
</style>
